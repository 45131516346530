import axios from 'axios';
import PropTypes from 'prop-types';

import { composeUrl } from '@motusi/app/modules/core/http/http.utils';

import 'app/modules/core/http/interceptors/jwt.interceptor';
import 'app/modules/core/http/interceptors/error.interceptor';

/**
 * @param {string} url
 * @param {Object || null} params
 * @return {Promise<any>}
 */
function get(url, params = null) {
  return axios.get(composeUrl(url), { params }).then((response) => response.data);
}

get.propTypes = {
  url: PropTypes.string.isRequired,
};

/**
 * @param {string} url
 * @param {Object} data
 * @return {Promise<any>}
 */
function put(url, data = null) {
  return axios.put(composeUrl(url), data).then((response) => response.data);
}

put.propTypes = {
  put: PropTypes.string.isRequired,
  data: PropTypes.object,
};

/**
 * @param {string} url
 * @param {Object} data
 * @return {Promise<any>}
 */
function post(url, data = null) {
  return axios.post(composeUrl(url), data).then((response) => response.data);
}

post.propTypes = {
  put: PropTypes.string.isRequired,
  data: PropTypes.object,
};

/**
 * @param {string} url
 * @param {Object} data
 * @return {Promise<any>}
 */
function upload(url, data) {
  return axios.post(url, data).then((response) => response.data);
}

post.propTypes = {
  put: PropTypes.string.isRequired,
  data: PropTypes.object,
};

/**
 * @param {string} url
 * @return {Promise<any>}
 */
function remove(url) {
  return axios.delete(composeUrl(url)).then((response) => response.data);
}

remove.propTypes = {
  url: PropTypes.string.isRequired,
};

export const httpRequests = { get, put, post, remove, upload };

import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import PropTypes from 'prop-types';

import { UserPool } from './auth-user-pool.service';

/**
 * Return Cognito User
 * @param {string} email
 * @return {CognitoUser}
 */
export function getCognitoUser(email) {
  return new CognitoUser({ Username: email, Pool: UserPool });
}

getCognitoUser.propTypes = {
  email: PropTypes.string.isRequired,
};

/**
 * Return Cognito Authentication Details
 * @param {string} email
 * @param {string} password
 * @return {AuthenticationDetails}
 */
export function getCognitoAuthenticationDetails(email, password) {
  return new AuthenticationDetails({ Username: email, Password: password });
}

getCognitoAuthenticationDetails.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export const ROLES = {
  admin: 1,
  pro: 2,
  member: 3,
};

export const ROLE_NAMES = {
  admin: 'SpaceAdmin',
  pro: 'ProUser',
  member: 'Member',
};

export const INVITE_STATUSES = {
  pending: 1,
  accepted: 2,
  declined: 3,
  expired: 4,
};

/**
 * Return Label By Value
 * @param {'SpaceAdmin' || 'ProUser' || 'Member'} value
 * @return {string}
 */
export function getRoleLabel(value) {
  return (
    {
      SpaceAdmin: 'Admin',
      ProUser: 'Pro',
    }[value] || ROLE_NAMES.member
  );
}

import React, { createContext, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { getProfile } from '@motusi/app/modules/core/rest/core/me.rest';
import { tokenStorage } from '@motusi/app/modules/core/services';
import { usePreloader } from '@motusi/app/modules/core/contexts/Preloader';
import {
  getCognitoAuthenticationDetails,
  getCognitoUser,
} from '@motusi/app/modules/core/auth/services/auth.helper';
import { UserPool } from '@motusi/app/modules/core/auth/services/auth-user-pool.service';

// Context
const AuthContext = createContext();

// Provider
export function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const { onPreloader, offPreloader } = usePreloader();

  async function saveProfile() {
    return getProfile({ withPhoto: true, withEmail: true }).then((data) => {
      setProfile(data);
      return data;
    });
  }

  /**
   * Refresh Token Method
   * @param {CognitoUser} user
   * @param {string} email
   * @param {CognitoRefreshToken} token
   */
  function refreshToken(user, email, token) {
    setInterval(() => {
      user.refreshSession(token, (error, session) => {
        if (error) {
          throw error;
        }

        tokenStorage.write(session.getIdToken().getJwtToken());
      });
    }, 7200000); // every 20 minutes
  }

  /**
   * Sign In Method
   * @param {string} email
   * @param {string} password
   * @return {Promise<void>}
   */
  async function signIn(emailCI, password) {
    onPreloader();
    const email = emailCI.toLowerCase();
    const user = getCognitoUser(email);
    setCognitoUser(user);

    return new Promise((success, onFailure) => {
      user.authenticateUser(getCognitoAuthenticationDetails(email, password), {
        onSuccess: (data) => {
          tokenStorage.write(data.getAccessToken().getJwtToken());
          refreshToken(user, email, data.getRefreshToken());
          success(data);
        },
        onFailure,
      });
    }).finally(offPreloader);
  }

  /**
   * Sign Up Method
   * @param {string} email
   * @param {string} password
   * @return {Promise<void>}
   */
  async function signUp(email, password) {
    onPreloader();
    return new Promise((onSuccess, onFailure) => {
      UserPool.signUp(email.toLowerCase(), password, [], null, (error, data) => {
        if (error) {
          onFailure(error);
          return;
        }

        onSuccess(data);
      });
    }).finally(offPreloader);
  }

  /**
   * Confirm Registration Method
   * @param {string} code
   * @param {string} email
   */
  function confirmRegistration(code, email) {
    onPreloader();
    return new Promise((onSuccess, onFailure) => {
      getCognitoUser(email.toLowerCase()).confirmRegistration(code, true, (error, data) => {
        if (error) {
          onFailure(error);
          return;
        }

        onSuccess(data);
      });
    }).finally(offPreloader);
  }

  /**
   * Resend Confirmation Code
   * @param {string} email
   */
  function resendConfirmationCode(email) {
    onPreloader();
    return new Promise((onSuccess, onFailure) => {
      getCognitoUser(email.toLowerCase()).resendConfirmationCode((error, data) => {
        if (error) {
          onFailure(error);
          return;
        }

        onSuccess(data);
      });
    }).finally(offPreloader);
  }

  /**
   * Forgot Password Method
   * @param {string} email
   * @return {Promise<*>}
   */
  async function forgotPassword(email) {
    onPreloader();
    const user = getCognitoUser(email.toLowerCase());
    setCognitoUser(user);

    return new Promise((onSuccess, onFailure) => {
      user.forgotPassword({ onSuccess, onFailure });
    }).finally(offPreloader);
  }

  /**
   * Confirm Password Method
   * @param {string} code
   * @param {string} newPassword
   * @return {Promise<minimist.Opts.unknown>}
   */
  async function confirmPassword(code, newPassword) {
    onPreloader();
    return new Promise((onSuccess, onFailure) => {
      cognitoUser.confirmPassword(code, newPassword, { onSuccess, onFailure });
    }).finally(offPreloader);
  }

  function signOut() {
    tokenStorage.remove();
    localStorage.clear();
    setProfile(null);
  }

  useEffect(async () => {
    // @todo Improve it after defining a strategy
    window.addEventListener('unload', signOut);

    // if (tokenStorage.read()) {
    //   saveProfile();
    // }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signUp,
        signIn,
        signOut,
        confirmRegistration,
        resendConfirmationCode,
        forgotPassword,
        confirmPassword,
        profile,
        cognitoUser,
        saveProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const useAuth = () => useContext(AuthContext);

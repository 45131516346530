import { common } from '@material-ui/core/colors';
import colors from '@motusi/styles/theme/colors';

export default {
  colorInherit: {
    color: common.white,
  },
  colorPrimary: {
    color: colors.primary['300'],
  },
};

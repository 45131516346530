import axios from 'axios';

import { AUTH_PATH } from '@motusi/app/modules/core/routing/routes/root.routes';
import { tokenStorage } from '@motusi/app/modules/core/services';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      tokenStorage.remove();
      window.location.href = AUTH_PATH;
    }

    return Promise.reject(error);
  }
);

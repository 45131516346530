import colors from '@motusi/styles/theme/colors';

export const motusiTitleTextStyle = {
  fontSize: '36px',
  lineHeight: '36px',
  fontWeight: 700,
  textTransform: 'uppercase',
};

export const motusiTitleLineStyle = {
  width: '65px',
  marginTop: '16px',
  border: `2px solid ${colors.primary['200']}`,
};

/**
 * Animation for View Block
 * @param {string} delay
 * @param {string} direction
 */
export function showUAnimation(delay = '1', direction = 'left') {
  return {
    animation: direction === 'left' ? `showUpLeft ${delay}s` : `showUpRight ${delay}s`,
  };
}

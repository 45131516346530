import React from 'react';

import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import { useMotusiLabelButtonStyles } from '@motusi/app/modules/common-ui/components/MotusiLabelButton/style';
import colors from '@motusi/styles/theme/colors';

// Component
const MotusiLabelButton = ({ children, bgColor, onClick, ...pros }) => {
  const classes = useMotusiLabelButtonStyles();

  return (
    <Button
      {...pros}
      className={classes.root}
      onClick={onClick}
      style={{ backgroundColor: bgColor }}
    >
      {children}
    </Button>
  );
};

MotusiLabelButton.defaultProps = {
  children: '',
  onClick: () => null,
  bgColor: colors.primary['200'],
};

MotusiLabelButton.propTypes = {
  children: PropTypes.node,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default MotusiLabelButton;

import loadable from '@loadable/component';

// Lazy loaded components
const Auth = loadable(() => import('@motusi/app/modules/pages/Auth/index'));
const Dashboard = loadable(() => import('@motusi/app/modules/pages/Dashboard'));
const NewSpace = loadable(() => import('@motusi/app/modules/pages/NewSpace'));
const Space = loadable(() => import('@motusi/app/modules/pages/Space'));

// Routes' paths constants
export const ROOT_PATH = '/';
export const AUTH_PATH = '/auth';
export const DASHBOARD_PATH = '/dashboard';
export const NEW_SPACE_PATH = '/new-space';
export const SPACE_PATH = '/space';

// Routes config
export const ROOT_ROUTES = [
  {
    path: ROOT_PATH,
    exact: true,
    redirect: DASHBOARD_PATH,
  },
  {
    path: AUTH_PATH,
    component: Auth,
    exact: false,
  },
  {
    path: NEW_SPACE_PATH,
    component: NewSpace,
    exact: false,
  },
  {
    path: DASHBOARD_PATH,
    component: Dashboard,
    exact: true,
  },
  {
    path: `${SPACE_PATH}/:spaceId`,
    component: Space,
    exact: false,
    notReloadableKey: 'spaceId',
  },
  {
    path: '*',
    redirect: ROOT_PATH,
  },
];

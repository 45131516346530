import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';
import PropTypes from 'prop-types';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Avatar, Box, Button } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import { useAuth } from '@motusi/app/modules/core/contexts/Auth';
import MotusiDialog from '@motusi/app/modules/common-ui/dialogs/MotusiDialog';
import MotusiInput from '@motusi/app/modules/common-ui/components/MotusiInput';
import { MAX_EMAIL_LENGTH } from '@motusi/app/modules/core/auth/validation/constants';
import avatar from '@motusi/assets/img/avatar.png';
import {
  getUserPhotoUploadLink,
  updateProfile,
  uploadUserProfilePhoto,
} from '@motusi/app/modules/core/rest/core/me.rest';
import { SIGN_IN_PATH } from '@motusi/app/modules/core/routing/routes/auth.routes';
import firstNameIcon from '@motusi/assets/img/first-name.svg';
import lastNameIcon from '@motusi/assets/img/last-name.svg';
import MotusiLabelButton from '@motusi/app/modules/common-ui/components/MotusiLabelButton';

import { useProfileDialogStyles } from './style';

// Validation Setting
const validationSchema = yup.object({
  email: yup
    .string()
    .trim(`The field can't include leading and trailing spaces`)
    .strict()
    .required('Email is required')
    .email('Please enter a valid email')
    .max(MAX_EMAIL_LENGTH, `You exceeded max email length (${MAX_EMAIL_LENGTH} characters)`),
  firstName: yup.string().required('Field is required'),
  lastName: yup.string().required('Field is required'),
  phoneNumber: yup.string().required('Field is required'),
});

// Initial Values
const INITIAL_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

// Component
const UserProfileDialog = ({ open, onClose }) => {
  const history = useHistory();
  const classes = useProfileDialogStyles();
  const { profile, cognitoUser, saveProfile, signOut } = useAuth();
  const [uploadedPhoto, setUploadedPhoto] = useState();
  const [uploadedPhotoFile, setUploadedPhotoFile] = useState();
  const [cognitoUserName, setCognitoUserName] = useState();

  useEffect(() => {
    if (cognitoUser) {
      setCognitoUserName(cognitoUser.getUsername());
    }
  }, [cognitoUser]);

  useEffect(() => {
    if (uploadedPhotoFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedPhoto(reader.result);
      };
      reader.readAsDataURL(uploadedPhotoFile);
    }
  }, [uploadedPhotoFile]);

  /**
   * Update User Data Method
   * @param {string} email
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} phoneNumber
   * @param setErrors
   * @return {Promise<void>}
   */
  async function onSubmit({ email, firstName, lastName, phoneNumber }, { setErrors }) {
    try {
      if (email !== cognitoUserName) {
        const emailAddress = new CognitoUserAttribute({
          Name: 'email',
          Value: email,
        });

        cognitoUser.updateAttributes([emailAddress], (error) => {
          if (error) {
            throw error;
          }
        });
      }

      await updateProfile({ firstName, lastName, phoneNumber });
      await saveProfile();

      if (uploadedPhotoFile) {
        const { url, fields } = await getUserPhotoUploadLink();
        await uploadUserProfilePhoto(url, fields, uploadedPhotoFile);
      }

      onClose();
    } catch (error) {
      setErrors({ email: error.message });
    }
  }

  function logout() {
    signOut();
    history.push(SIGN_IN_PATH);
    onClose();
  }

  function contentComponent({ handleChange }) {
    return (
      <Box className={classes.root}>
        <Box className={classes.container} style={{ alignItems: 'center', flex: 1 }}>
          <Avatar
            alt="Profile"
            style={{ height: '100px', width: '100px' }}
            src={uploadedPhoto || (profile && profile.photo) || avatar}
          />

          <label htmlFor="uploadProfilePhoto" style={{ margin: '10px 0', width: '100%' }}>
            <input
              onChange={({ target }) => {
                setUploadedPhotoFile(target.files[0]);
              }}
              accept="image/*"
              id="uploadProfilePhoto"
              type="file"
              hidden
            />
            <MotusiLabelButton component="div">Edit Photo</MotusiLabelButton>
          </label>
        </Box>
        <Box className={classes.container}>
          <MotusiInput
            controlStyle={{ marginTop: '20px' }}
            onChange={handleChange}
            name="email"
            placeholder="Enter email"
            leftElement={<EmailIcon />}
          />
          <MotusiInput
            controlStyle={{ marginTop: '20px' }}
            onChange={handleChange}
            name="firstName"
            placeholder="Enter your first name"
            leftElement={<img src={firstNameIcon} alt="First Name" />}
          />
          <MotusiInput
            controlStyle={{ marginTop: '20px' }}
            onChange={handleChange}
            name="lastName"
            placeholder="Enter your last Name"
            leftElement={<img src={lastNameIcon} alt="Last Name" />}
          />
          <MotusiInput
            type="phone"
            name="phoneNumber"
            controlStyle={{ marginTop: '20px' }}
            placeholder="Enter phone"
            onChange={handleChange}
            leftElement={<PhoneIcon />}
          />
        </Box>
      </Box>
    );
  }

  return (
    <MotusiDialog
      title="Your Profile"
      onClose={onClose}
      open={open}
      validationSchema={validationSchema}
      initialValue={{ ...INITIAL_VALUES, ...profile, email: cognitoUserName }}
      ContentComponent={contentComponent}
      ActionComponent={({ handleSubmit }) => (
        <Box style={{ width: '100%' }}>
          <Button color="primary" style={{ marginTop: 40, width: '100%' }} onClick={handleSubmit}>
            Save
          </Button>

          <Button className={classes.logoutButton} onClick={logout}>
            Logout
          </Button>
        </Box>
      )}
      onSubmit={onSubmit}
    />
  );
};

UserProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserProfileDialog;

const colors = {
  primary: {
    200: '#53B9D5',
    300: '#4ea2bb',
    400: '#499DB8',
    450: '#3D8CA1',
    500: '#33788b',
  },
  secondary: {
    50: '#c4cad5',
    100: '#728588',
    500: '#031626',
    700: '#1B263B',
  },
  red: {
    main: '#cf012b',
  },
};

export default colors;

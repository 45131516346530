import React, { createContext, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { getSpaces } from '@motusi/app/modules/core/rest/core/me.rest';
import { useAuth } from '@motusi/app/modules/core/contexts/Auth';

// Context
const SpacesContext = createContext();

// Provider
export function SpacesProvider({ children }) {
  const { profile } = useAuth();
  const [spaces, setSpaces] = useState([]);
  const [selectedSpaceId, setSelectedSpaceId] = useState(0);

  /**
   * Return Spaces List
   */
  function get() {
    getSpaces().then((data) => {
      setSpaces(data.data);
    });
  }

  useEffect(() => {
    if (profile) {
      get();
    }
  }, [profile]);

  return (
    <SpacesContext.Provider value={{ spaces, selectedSpaceId, setSelectedSpaceId, get }}>
      {children}
    </SpacesContext.Provider>
  );
}

SpacesProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const useSpaces = () => useContext(SpacesContext);

import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { common } from '@material-ui/core/colors';

// Component
const MotusiDialog = ({
  ContentComponent,
  ActionComponent,
  validationSchema,
  initialValue,
  onSubmit,
  open,
  onClose,
  title,
  text,
  submitListFields,
}) => {
  const dialogRef = useRef(null);

  return (
    <Dialog
      keepMounted={false}
      scroll="paper"
      onClose={onClose}
      aria-labelledby="confirm-password-dialog"
      open={open}
      ref={dialogRef}
    >
      <DialogTitle style={{ textAlign: 'center' }} id="confirm-password-dialog">
        {title}
        <Button
          style={{
            position: 'absolute',
            top: '6px',
            right: '0',
            zIndex: 9999,
            height: '50px',
            width: '50px',
          }}
          onClick={onClose}
        >
          <CloseIcon style={{ fill: common.white, padding: 0 }} />
        </Button>
      </DialogTitle>

      {text ? <DialogContentText color="initial">{text}</DialogContentText> : null}

      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValue}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, values, setErrors, setValues, setFieldValue }) => (
          <Form>
            <DialogContent>
              <ContentComponent handleChange={handleChange} handleSubmit={handleSubmit} />
            </DialogContent>

            {ActionComponent ? (
              <DialogActions style={{ justifyContent: 'center' }}>
                <ActionComponent
                  handleSubmit={() => {
                    if (submitListFields) {
                      onSubmit(values, { setErrors, setValues, setFieldValue });
                    } else {
                      handleSubmit(values, { setErrors });
                    }
                  }}
                />
              </DialogActions>
            ) : null}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

MotusiDialog.defaultProps = {
  text: '',
  ActionComponent: null,
  validationSchema: null,
  initialValue: {},
  onSubmit: () => null,
  submitListFields: false,
};

MotusiDialog.propTypes = {
  text: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValue: PropTypes.object,
  ActionComponent: PropTypes.func,
  validationSchema: PropTypes.object,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  ContentComponent: PropTypes.func.isRequired,
  submitListFields: PropTypes.bool,
};

export default MotusiDialog;

import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { motusiTitleLineStyle, motusiTitleTextStyle } from './style';

// Component
const MotusiTitle = ({ children, style, textStyle, lineStyle, onClick }) => {
  return (
    <Box style={style} onClick={onClick}>
      <Typography style={{ ...motusiTitleTextStyle, ...textStyle }}>{children}</Typography>
      <Box style={{ ...motusiTitleLineStyle, ...lineStyle }} />
    </Box>
  );
};

MotusiTitle.defaultProps = {
  style: null,
  textStyle: null,
  lineStyle: null,
  onClick: () => null,
};

MotusiTitle.propTypes = {
  style: PropTypes.object,
  textStyle: PropTypes.object,
  lineStyle: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
};

export default MotusiTitle;

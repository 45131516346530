import { common } from '@material-ui/core/colors';

export default {
  root: {
    color: common.white,
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: '18px',
    textAlign: 'center',
  },
};

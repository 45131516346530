import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import PropTypes from 'prop-types';
import { Avatar, Box, Button } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';

import MotusiDialog from '@motusi/app/modules/common-ui/dialogs/MotusiDialog';
import MotusiInput from '@motusi/app/modules/common-ui/components/MotusiInput';
import avatar from '@motusi/assets/img/avatar.png';
import { useSpaces } from '@motusi/app/modules/core/contexts/Spaces';

import {
  getLogoUploadLink,
  getSpaceSettingsById,
  updateSpaceSettings,
  uploadSpaceLogo,
} from '@motusi/app/modules/core/rest/core/spaces.rest';
import firstNameIcon from '@motusi/assets/img/first-name.svg';
import lastNameIcon from '@motusi/assets/img/last-name.svg';
import MotusiLabelButton from '@motusi/app/modules/common-ui/components/MotusiLabelButton';

import { getOnlyNumber } from '@motusi/app/modules/core/core.utils';
import { useSpaceSettingsDialogStyles } from './style';

// Validation Setting
const validationSchema = yup.object({
  companyName: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
  phoneNumber: yup.string().required('Field is required'),
  location: yup.string().required('Field is required'),
});

// Initial Values
const INITIAL_VALUES = {
  companyName: '',
  description: '',
  phoneNumber: '',
  location: '',
};

// Component
const SpaceSettingsDialog = ({ id, onClose }) => {
  const { get: getSpaces } = useSpaces();
  const classes = useSpaceSettingsDialogStyles();
  const [uploadedPhoto, setUploadedPhoto] = useState();
  const [uploadedPhotoFile, setUploadedPhotoFile] = useState();
  const [space, setSpace] = useState(null);

  useEffect(async () => {
    if (id) {
      setSpace(await getSpaceSettingsById(id));
    }
  }, [id]);

  useEffect(() => {
    if (uploadedPhotoFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedPhoto(reader.result);
      };
      reader.readAsDataURL(uploadedPhotoFile);
    }
  }, [uploadedPhotoFile]);

  /**
   * Update Space Data Method
   * @param {Object} data
   * @param setErrors
   * @return {Promise<void>}
   */
  async function onSubmit(data, { setErrors }) {
    if (JSON.stringify(data) !== JSON.stringify(space)) {
      await updateSpaceSettings(id, { ...data, phoneNumber: getOnlyNumber(data.phoneNumber) });
      getSpaces();
    }

    try {
      if (uploadedPhotoFile) {
        const { url, fields } = await getLogoUploadLink(id);
        await uploadSpaceLogo(url, fields, uploadedPhotoFile);
      }

      onClose();
    } catch (error) {
      setErrors({ address: error.message });
    }
  }

  function contentComponent({ handleChange }) {
    return (
      <Box className={classes.root}>
        <Box className={classes.container} style={{ alignItems: 'center', flex: 1 }}>
          <Avatar
            alt="Space"
            style={{ height: '100px', width: '100px' }}
            src={uploadedPhoto || space?.logo || avatar}
          />

          <label htmlFor="uploadProfilePhoto" style={{ margin: '10px 0', width: '100%' }}>
            <input
              onChange={({ target }) => {
                setUploadedPhotoFile(target.files[0]);
              }}
              accept="image/*"
              id="uploadProfilePhoto"
              type="file"
              hidden
            />
            <MotusiLabelButton component="div">Edit Photo</MotusiLabelButton>
          </label>
        </Box>
        <Box className={classes.container}>
          <MotusiInput
            controlStyle={{ marginTop: '20px' }}
            onChange={handleChange}
            name="companyName"
            placeholder="Name Your Space"
            leftElement={<img src={firstNameIcon} alt="Name Your Space" />}
          />
          <MotusiInput
            controlStyle={{ marginTop: '20px' }}
            onChange={handleChange}
            name="description"
            placeholder="Description of Space"
            leftElement={<img src={lastNameIcon} alt="Description of Space" />}
          />
          <MotusiInput
            type="phone"
            name="phoneNumber"
            controlStyle={{ marginTop: '20px' }}
            placeholder="(XXX) XXX-XXXX"
            onChange={handleChange}
            leftElement={<PhoneIcon />}
          />
          <MotusiInput
            name="location"
            controlStyle={{ marginTop: '20px' }}
            placeholder="Address"
            onChange={handleChange}
            leftElement={<img src={lastNameIcon} alt="Address" />}
          />
        </Box>
      </Box>
    );
  }

  return (
    <MotusiDialog
      title={`${space?.companyName} Settings`}
      onClose={onClose}
      open={Boolean(id)}
      validationSchema={validationSchema}
      initialValue={{ ...INITIAL_VALUES, ...space }}
      ContentComponent={contentComponent}
      ActionComponent={({ handleSubmit }) => (
        <Box className={classes.actionContainer}>
          <Button
            color="primary"
            style={{ marginTop: '40px', width: '100%' }}
            onClick={handleSubmit}
          >
            Save
          </Button>

          <Button className={classes.deleteButton} onClick={() => null}>
            Delete Space
          </Button>
        </Box>
      )}
      onSubmit={onSubmit}
    />
  );
};

SpaceSettingsDialog.defaultProps = {
  id: null,
};

SpaceSettingsDialog.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default SpaceSettingsDialog;

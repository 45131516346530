import { common } from '@material-ui/core/colors';
import colors from '@motusi/styles/theme/colors';

export default {
  root: {
    textTransform: 'uppercase',
    color: common.white,
    fontFamily: 'Oswald',
    fontWeight: '700',
    fontSize: '20px',

    '&:disabled': {
      opacity: 0.3,
    },
  },
  containedPrimary: {
    backgroundColor: colors.secondary['700'],
    color: colors.primary['400'],

    '&:hover': {
      backgroundColor: colors.secondary['700'],
    },

    '&:disabled': {
      backgroundColor: colors.secondary['700'],
      color: colors.primary['400'],
      opacity: 0.3,
    },
  },
  containedSecondary: {
    backgroundColor: colors.primary['200'],
    color: common.white,
    fontFamily: 'Oswald',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    paddingTop: '13px',
    paddingBottom: '13px',

    '&:hover': {
      backgroundColor: colors.primary['200'],
    },

    '&:disabled': {
      backgroundColor: colors.primary['200'],
      color: common.white,
      opacity: 0.3,
    },
  },
};

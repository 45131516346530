import PropTypes from 'prop-types';

import { AUTH_PATH } from '@motusi/app/modules/core/routing/routes/root.routes';
import {
  FORGOT_PASSWORD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
} from '@motusi/app/modules/core/routing/routes/auth.routes';

const PUBLIC_ROUTES = Object.freeze([AUTH_PATH, SIGN_UP_PATH, SIGN_IN_PATH, FORGOT_PASSWORD_PATH]);

/**
 * Check If the Route is Public
 * @param {string} path
 * @return {boolean}
 */
export function isPublicRoute(path) {
  return PUBLIC_ROUTES.includes(path);
}

isPublicRoute.propTypes = {
  path: PropTypes.string.isRequired,
};

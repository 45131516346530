import React from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';
import { Box, FormControl, FormHelperText, InputAdornment } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { PhoneMask } from '@motusi/app/modules/common-ui/components/MotusiInput/masks';
import flag from '@motusi/assets/img/flag.png';

// Component
const MotusiInput = ({
  type,
  name,
  placeholder,
  onChange,
  rightElement,
  leftElement,
  controlStyle,
  readOnly,
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const { errors, touched } = form;
        const isTouched = touched?.[name];
        const errorMessage = errors?.[name];

        const isInvalid = Boolean(isTouched && errorMessage);

        function isPhoneType() {
          return type === 'phone';
        }

        return (
          <FormControl error={isInvalid} style={controlStyle}>
            <OutlinedInput
              {...(isPhoneType() ? { inputComponent: PhoneMask } : null)}
              {...field}
              type={type}
              id={name}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              autoComplete="off"
              readOnly={readOnly}
              startAdornment={
                leftElement && (
                  <InputAdornment position="start">
                    {isPhoneType() ? (
                      <Box>
                        {leftElement}
                        <img style={{ marginLeft: '10px', height: '22px' }} src={flag} alt="flag" />
                      </Box>
                    ) : (
                      leftElement
                    )}
                  </InputAdornment>
                )
              }
              endAdornment={
                rightElement && <InputAdornment position="end">{rightElement}</InputAdornment>
              }
            />

            {isInvalid && (
              <FormHelperText style={{ paddingLeft: 14, position: 'absolute', top: 40 }}>
                {errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

MotusiInput.defaultProps = {
  placeholder: '',
  rightElement: null,
  leftElement: null,
  type: 'text',
  controlStyle: {},
  readOnly: false,
  onChange: () => null,
};

MotusiInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rightElement: PropTypes.object,
  leftElement: PropTypes.object,
  type: PropTypes.string,
  controlStyle: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default MotusiInput;

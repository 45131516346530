import PropTypes from 'prop-types';

import { httpRequests } from '@motusi/app/modules/core/http';

/**
 * Create and Activation Space
 * @param data
 * @return {Promise<{spaceId: number}>}
 */
export async function createSpace(data) {
  return httpRequests.post('spaces/activation', data);
}

createSpace.propTypes = {
  data: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    activationCode: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * Return a Link for Upload Logo
 * @param {string} spaceId
 * @return {Promise<{fields: {}, url: string}>}
 */
export async function getLogoUploadLink(spaceId) {
  return httpRequests.get(`spaces/${spaceId}/logo-upload-link`);
}

getLogoUploadLink.propTypes = {
  spaceId: PropTypes.string.isRequired,
};

/**
 * Upload logo for Space
 * @param {string} url
 * @param {Object} fields
 * @param {File} file
 * @return {Promise<*>}
 */
export async function uploadSpaceLogo(url, fields, file) {
  const data = new FormData();
  Object.entries(fields).forEach(([key, value]) => {
    data.append(key, value);
  });

  data.append('Content-type', file.type);
  data.append('file', file, 'space_logo');

  return httpRequests.upload(url, data);
}

uploadSpaceLogo.propTypes = {
  url: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  file: PropTypes.instanceOf(File).isRequired,
};

/**
 * Create or Update Space Admin Profile
 * @param {string} spaceId
 * @param {Object} data
 * @return {Promise<boolean>}
 */
export async function updateSpaceAdminProfile(spaceId, data) {
  return httpRequests.put(`spaces/${spaceId}/staff/profile`, data);
}

uploadSpaceLogo.propTypes = {
  spaceId: PropTypes.string.isRequired,
  data: PropTypes.object,
};

/**
 * Update Space Settings
 * @param {string} spaceId
 * @param {Object} data
 * @return {Promise<boolean>}
 */
export async function updateSpaceSettings(spaceId, data) {
  return httpRequests.put(`spaces/${spaceId}/profile`, data);
}

updateSpaceSettings.propTypes = {
  spaceId: PropTypes.string.isRequired,
  data: PropTypes.object,
};

/**
 * Return Space Settings
 * @param {number} spaceId
 * @return {Promise<{fields: {}, url: string}>}
 */
export async function getSpaceSettingsById(spaceId) {
  return httpRequests.get(`spaces/${spaceId}/profile`);
}

getSpaceSettingsById.propTypes = {
  spaceId: PropTypes.number.isRequired,
};

/**
 * Send Invite to Member
 * @param {number} spaceId
 * @param {string} email
 * @return {Promise<*>}
 */
export async function sendMemberInvite(spaceId, email) {
  return httpRequests.post(`spaces/${spaceId}/assignment-invites`, { email });
}

sendMemberInvite.propTypes = {
  spaceId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
};

/**
 * Return Info about Members Invites
 * @param {number} spaceId
 * @return {Promise<*>}
 */
export async function getMembersInvitesInfo(spaceId) {
  return httpRequests.get(`spaces/assignment-invites`, { spaceId });
}

getMembersInvitesInfo.propTypes = {
  spaceId: PropTypes.number.isRequired,
};

/**
 * Send Invite to Pro or Admin
 * @param {number} spaceId
 * @param {string} email
 * @param {number} role
 * @return {Promise<*>}
 */
export async function sendInvite(spaceId, email, role) {
  return httpRequests.post(`spaces/${spaceId}/invitations`, { email, role });
}

sendInvite.propTypes = {
  spaceId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.number.isRequired,
};

/**
 * Send Invites Array to Pro or Admin
 * @param {number} spaceId
 * @param {string[]} emails
 * @return {Promise<*>}
 */
export async function sendInvites(spaceId, emails) {
  return httpRequests.post(`spaces/${spaceId}/batch-assignment-invites`, { emails });
}

sendInvites.propTypes = {
  spaceId: PropTypes.number.isRequired,
  emails: PropTypes.array.isRequired,
};

/**
 * Re-send Invite to Pro or Admin
 * @param {string} token
 * @return {Promise<*>}
 */
export async function resendInvite(token) {
  return httpRequests.put(`spaces/invitations/${token}`);
}

resendInvite.propTypes = {
  token: PropTypes.string.isRequired,
};

/**
 * Return Info about Admins or Pros Invites
 * @param {number} spaceId
 * @return {Promise<*>}
 */
export async function getInvitesInfo(spaceId) {
  return httpRequests.get(`spaces/${spaceId}/invitations`);
}

getInvitesInfo.propTypes = {
  spaceId: PropTypes.number.isRequired,
};

/**
 * Return of Details Admins or Pros of Space
 * @param {number} spaceId
 * @param {Object} params
 * @return {Promise<*>}
 */
export async function getStaffs(spaceId, params = {}) {
  return httpRequests.get(`spaces/${spaceId}/staff`, params);
}

getStaffs.propTypes = {
  params: PropTypes.object,
  spaceId: PropTypes.number.isRequired,
};

/**
 * Return Data Profile by Staff Id
 * @param {number} spaceId
 * @param {number} staffId
 * @param {Object} params
 * @return {Promise<*>}
 */
export async function getSpaceStaffProfile(spaceId, staffId, params = {}) {
  return httpRequests.get(`spaces/${spaceId}/staff/${staffId}/profile`, params);
}

getStaffs.propTypes = {
  params: PropTypes.object,
  spaceId: PropTypes.number.isRequired,
};

/**
 * Return Members Details by Staff
 * @param {number} spaceId
 * @param {Object} params
 * @return {Promise<*>}
 */
export async function getMembers(spaceId, params = {}) {
  return httpRequests.get(`spaces/${spaceId}/members`, params);
}

getMembers.propTypes = {
  params: PropTypes.object,
  spaceId: PropTypes.number.isRequired,
};

/**
 * Change Members' Status in Staff
 * @param {number} spaceId
 * @param {string} userId
 * @param {string} status
 * @return {Promise<*>}
 */
export async function changeMemberStatus(spaceId, userId, status) {
  return httpRequests.put(`spaces/${spaceId}/users/${userId}/status`, { status });
}

changeMemberStatus.propTypes = {
  spaceId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

/**
 * Return Programs of Space
 * @param {number} spaceId
 * @param {Object} params
 * @return {Promise<*>}
 */
export async function getPrograms(spaceId, params = {}) {
  return httpRequests.get(`spaces/${spaceId}/programs`, params);
}

getPrograms.propTypes = {
  params: PropTypes.object,
  spaceId: PropTypes.number.isRequired,
};

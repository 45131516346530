import { makeStyles } from '@material-ui/core';

export const useAppStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    animation: 'fadeIn 1s',
  },
  preloadBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9,
    backgroundColor: theme.palette.secondary['500'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  preloadLogo: {
    height: '20vh',
    width: 'auto',
    animation: 'spinY 1s infinite',
  },
}));

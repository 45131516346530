import PropTypes from 'prop-types';

import { httpRequests } from '@motusi/app/modules/core/http';

/**
 * Return Data Profile
 * @param params
 * @return {
 *  Promise<{
 *    firstName: string,
 *    lastName: string,
 *    phoneNumber:string,
 *    email:string,
 *    userId:string,
 *    photo: string
 *  }>
 * }
 */
export function getProfile(params = {}) {
  return httpRequests.get('me/profile', params);
}

/**
 * Update Data Profile
 * @return {Promise<any>}
 */
export function updateProfile(data) {
  return httpRequests.put('me/profile', data);
}

updateProfile.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
};

/**
 * Return List of Spaces
 * @return {
 *  Promise<{
 *   pagination: {page: number, limit: number},
 *   data: {
 *    user: {
 *      id: number,
 *      role: 'SpaceAdmin' || 'ProUser' || 'Member',
 *      jobTitle: string,
 *      workPhoneNumber: string,
 *      workEmail: string,
 *    },
 *    space: {
 *      id: number,
 *      name: string,
 *      description: string,
 *      phoneNumber: string,
 *      logo: string,
 *      adminsCount: number,
 *      prosCount: number,
 *      membersCount: number,
 *    }
 *   }[]
 *  }>
 * }
 */
export function getSpaces() {
  return httpRequests.get('me/spaces', { includeExtraDetails: true });
}

/**
 * Return a Link for Upload Photo
 * @return {Promise<{fields: {}, url: string}>}
 */
export async function getUserPhotoUploadLink() {
  return httpRequests.get('me/profile/photo-upload-link');
}

/**
 * Upload photo for User Profile
 * @param {string} url
 * @param {Object} fields
 * @param {File} file
 * @return {Promise<*>}
 */
export async function uploadUserProfilePhoto(url, fields, file) {
  const data = new FormData();
  Object.entries(fields).forEach(([key, value]) => {
    data.append(key, value);
  });

  data.append('Content-type', file.type);
  data.append('file', file, 'user_logo');

  return httpRequests.upload(url, data);
}

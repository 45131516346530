import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Avatar, Box, Typography } from '@material-ui/core';

import Header from '@motusi/app/modules/common-ui/components/Header';
import Router from '@motusi/app/modules/core/routing/Router';
import {
  AUTH_PATH,
  DASHBOARD_PATH,
  ROOT_ROUTES,
} from '@motusi/app/modules/core/routing/routes/root.routes';
import bg from '@motusi/assets/img/bg.png';
import logo from '@motusi/assets/img/logo.svg';

import { useAppStyles } from './style';

function App() {
  const classes = useAppStyles();
  const { pathname } = useLocation();
  const [isLoadImage, setIsLoadImage] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => setIsLoadImage(true);
    image.onerror = () => setIsLoadImage(true);
    image.src = bg;

    return () => setIsLoadImage(false);
  }, []);

  function isShowBottomText() {
    return pathname.includes(DASHBOARD_PATH) || pathname.includes(AUTH_PATH);
  }

  return (
    <Box bgcolor="secondary.500">
      {isLoadImage ? (
        <Box className={classes.container}>
          <Header />
          <Router routes={ROOT_ROUTES} />

          {isShowBottomText() && (
            <Typography
              style={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 400,
                padding: '24px',
              }}
            >
              Terms & Conditions
            </Typography>
          )}
        </Box>
      ) : (
        <Box className={classes.preloadBlock}>
          <Avatar className={classes.preloadLogo} alt="Logo" src={logo} />
        </Box>
      )}
    </Box>
  );
}

export default App;

import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core';
import { cyan, indigo, common } from '@material-ui/core/colors';

import {
  MuiButton,
  MuiCheckbox,
  MuiCircularProgress,
  MuiDialog,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiLink,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiTypography,
} from '@motusi/styles/theme/components';
import colors from '@motusi/styles/theme/colors';

const overrides = {
  MuiOutlinedInput,
  MuiButton,
  MuiLink,
  MuiDialog,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiTypography,
  MuiCircularProgress,
  MuiMenuItem,
  MuiCheckbox,
};

const typography = {
  color: common.white,
  fontFamily: 'Oswald',
};

const palette = {
  action: {
    disabledBackground: colors.secondary['100'],
    disabled: common.black,
  },
  primary: { ...cyan, ...colors.primary },
  secondary: { ...indigo, ...colors.secondary },
  error: colors.red,
  common,
};

const customTheme = createTheme({
  typography,
  palette,
  overrides,
});

export default customTheme;

import { makeStyles } from '@material-ui/core';

export const useMotusiLabelButtonStyles = makeStyles((theme) => ({
  root: {
    padding: '5px 25px',
    lineHeight: '16px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    borderRadius: '26px',
  },
}));

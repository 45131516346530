import axios from 'axios';

import { tokenStorage } from '@motusi/app/modules/core/services';

axios.interceptors.request.use(
  async (request) => {
    const token = tokenStorage.read();
    if (
      request.url.includes('motusi-profile-photos') ||
      request.url.includes('motusi-space-photos')
    ) {
      return request;
    }

    request.headers = {
      Authorization: `Bearer ${token}`,
    };
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

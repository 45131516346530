import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Box, Button } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

import MotusiInput from '@motusi/app/modules/common-ui/components/MotusiInput';
import { useAuth } from '@motusi/app/modules/core/contexts/Auth';
import {
  DEFAULT_MAX_INPUT_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_REGEX,
} from '@motusi/app/modules/core/auth/validation/constants';
import { SIGN_IN_PATH } from '@motusi/app/modules/core/routing/routes/auth.routes';
import MotusiDialog from '@motusi/app/modules/common-ui/dialogs/MotusiDialog';

// Validation Setting
const validationSchema = yup.object({
  code: yup.string().required('Code is required'),
  newPassword: yup
    .string()
    .trim(`The field can't include leading and trailing spaces`)
    .strict()
    .required('Password is required')
    .matches(PASSWORD_REGEX, `Password doesn't follow the requirements`)
    .min(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters`)
    .max(
      DEFAULT_MAX_INPUT_LENGTH,
      `You exceeded max password length (${DEFAULT_MAX_INPUT_LENGTH} characters)`
    ),
  confirmPassword: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('newPassword')], `Passwords don't match`),
});

// Initial Values
const INITIAL_VALUES = {
  code: '',
  newPassword: '',
  confirmPassword: '',
};

// Component
const ConfirmPasswordDialog = ({ onClose, open, email }) => {
  const history = useHistory();
  const { confirmPassword } = useAuth();
  const [passwordFieldType, setPasswordFieldType] = useState({
    newPassword: 'password',
    confirmPassword: 'password',
  });

  /**
   * Confirm Password Method
   * @param {string} code
   * @param {string} newPassword
   * @param setErrors
   * @return {Promise<void>}
   */
  async function onSubmit({ code, newPassword }, { setErrors }) {
    try {
      await confirmPassword(code, newPassword, email);
      onClose();
      history.push(SIGN_IN_PATH);
    } catch (error) {
      setErrors({ confirmPassword: error.message });
    }
  }

  /**
   * change Type Of Password Field by Name
   * @param {string} name
   */
  function changeTypeOfPasswordField(name) {
    setPasswordFieldType({
      ...passwordFieldType,
      [name]: passwordFieldType[name] === 'text' ? 'password' : 'text',
    });
  }

  /**
   * @param {Function} handleChange
   * @return {JSX.Element}
   */
  function contentComponent({ handleChange }) {
    return (
      <>
        <MotusiInput
          onChange={handleChange}
          controlStyle={{ width: '100%' }}
          name="code"
          placeholder="Enter your password reset code"
          leftElement={<CodeIcon />}
        />
        <br />
        <br />
        <MotusiInput
          controlStyle={{ width: '100%' }}
          onChange={handleChange}
          type={passwordFieldType.newPassword}
          name="newPassword"
          placeholder="Enter new password"
          leftElement={<EnhancedEncryptionIcon />}
          rightElement={
            <Box
              style={{ cursor: 'pointer' }}
              onClick={() => changeTypeOfPasswordField('newPassword')}
            >
              {passwordFieldType.newPassword === 'text' ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </Box>
          }
        />
        <br />
        <br />
        <MotusiInput
          controlStyle={{ width: '100%' }}
          onChange={handleChange}
          type={passwordFieldType.confirmPassword}
          name="confirmPassword"
          placeholder="Enter new password"
          leftElement={<EnhancedEncryptionIcon />}
          rightElement={
            <Box
              style={{ cursor: 'pointer' }}
              onClick={() => changeTypeOfPasswordField('confirmPassword')}
            >
              {passwordFieldType.confirmPassword === 'text' ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </Box>
          }
        />
      </>
    );
  }

  /**
   * @param {Function} handleSubmit
   * @return {JSX.Element}
   */
  function actionComponent({ handleSubmit }) {
    return (
      <Button
        type="submit"
        color="primary"
        style={{ marginTop: 40, width: '100%' }}
        onClick={handleSubmit}
      >
        Confirm Password
      </Button>
    );
  }

  return (
    <MotusiDialog
      text="A confirmation code was sent to the specified postal address. Please enter and submit the code"
      onClose={onClose}
      open={open}
      title="Confirm Password"
      validationSchema={validationSchema}
      initialValue={INITIAL_VALUES}
      ContentComponent={contentComponent}
      ActionComponent={actionComponent}
      onSubmit={onSubmit}
    />
  );
};

ConfirmPasswordDialog.defaultProps = {
  onClose: () => null,
};

ConfirmPasswordDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

export default ConfirmPasswordDialog;

import PropTypes from 'prop-types';

/**
 * @todo improve the method
 * Composes Rest Url
 * @param {string} endpoint
 */
export function composeUrl(endpoint) {
  return `${process.env.REACT_APP_API_URL}/${endpoint}`;
}

composeUrl.propTypes = {
  endpoint: PropTypes.string.isRequired,
};

import colors from '@motusi/styles/theme/colors';

export default {
  colorSecondary: {
    color: colors.primary['200'],
    '&$checked': {
      color: colors.primary['200'],
    },
  },
};

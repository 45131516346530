import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

import { usePreloader } from '@motusi/app/modules/core/contexts/Preloader';

import { preloaderContainerStyles } from 'app/modules/common-ui/components/MotusiPreloader/style';

function MotusiPreloader() {
  const { isOnPreloader } = usePreloader();

  return isOnPreloader ? (
    <Box {...preloaderContainerStyles}>
      <CircularProgress size="70px" />
    </Box>
  ) : null;
}

export default MotusiPreloader;

import { makeStyles } from '@material-ui/core';

export const useHeaderStyles = makeStyles((theme) => ({
  avatar: {
    maxHeight: '28px',
    borderRadius: '28px',
  },
  menuButton: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary['450'],
    paddingLeft: '15px',
  },
  menuItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuAddIcon: {
    fill: theme.palette.primary['200'],
    fontSize: '30px',
  },
  menuItemIcon: {
    minWidth: '40px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  menuPaper: {
    backgroundColor: theme.palette.secondary['700'],
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  profileName: {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Oswald',
    textTransform: 'uppercase',
    marginLeft: '15px',
  },
  profileAvatar: {
    height: '44px',
    width: '44px',
  },
  settingsIcon: {
    fill: theme.palette.common.white,
    fontSize: '20px',
  },
  logoContainer: {
    flexGrow: 1,
    height: '83px',
  },
  logoAnimation: {
    width: '84px',
    cursor: 'pointer',

    '&:hover': {
      '& $logo': {
        animation: 'spinY .8s',
      },
    },
  },
  logo: {
    height: '84px',
    width: '84px',
  },
}));

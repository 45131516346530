import React from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Box, Button } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import PhoneIcon from '@material-ui/icons/Phone';

import MotusiInput from '@motusi/app/modules/common-ui/components/MotusiInput';
import { useAuth } from '@motusi/app/modules/core/contexts/Auth';
import MotusiDialog from '@motusi/app/modules/common-ui/dialogs/MotusiDialog';
import { updateProfile } from '@motusi/app/modules/core/rest/core/me.rest';
import { DASHBOARD_PATH } from '@motusi/app/modules/core/routing/routes/root.routes';
import firstNameIcon from '@motusi/assets/img/first-name.svg';
import lastNameIcon from '@motusi/assets/img/last-name.svg';
import MotusiLabelButton from '@motusi/app/modules/common-ui/components/MotusiLabelButton';

// Validation Setting
const validationSchema = yup.object({
  code: yup.string().required('Field is required'),
  firstName: yup.string().required('Field is required'),
  lastName: yup.string().required('Field is required'),
  phoneNumber: yup.string().required('Field is required'),
});

// Initial Values
const INITIAL_VALUES = {
  code: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

// Component
const ConfirmRegistrationDialog = ({ onClose, open, authData }) => {
  const { confirmRegistration, signIn, saveProfile, resendConfirmationCode } = useAuth();
  const history = useHistory();

  /**
   * Confirm Registration Method
   * @param {string} code
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} phoneNumber
   * @param setErrors
   * @return {Promise<void>}
   */
  async function onSubmit({ code, firstName, lastName, phoneNumber }, { setErrors }) {
    try {
      const { email, password } = authData;
      await confirmRegistration(code, email);
      await signIn(email, password);
      await updateProfile({ firstName, lastName, phoneNumber });
      await saveProfile();
      onClose();
      history.push(DASHBOARD_PATH);
    } catch (error) {
      setErrors({ code: error.message });
    }
  }

  return (
    <MotusiDialog
      text="Please enter the 6-digit code sent to your email address and complete your profile information"
      title="Setup Your Profile"
      onClose={onClose}
      open={open}
      validationSchema={validationSchema}
      initialValue={INITIAL_VALUES}
      ContentComponent={({ handleChange }) => (
        <>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <MotusiInput
              controlStyle={{ flex: 1, marginRight: '10px' }}
              onChange={handleChange}
              name="code"
              placeholder="Enter Code"
              leftElement={<CodeIcon />}
            />

            <MotusiLabelButton onClick={() => resendConfirmationCode(authData.email)}>
              Resend code
            </MotusiLabelButton>
          </Box>
          <MotusiInput
            controlStyle={{ marginTop: '20px', width: '100%' }}
            onChange={handleChange}
            name="firstName"
            placeholder="Enter your first name"
            leftElement={<img src={firstNameIcon} alt="First Name" />}
          />
          <MotusiInput
            controlStyle={{ marginTop: '20px', width: '100%' }}
            onChange={handleChange}
            name="lastName"
            placeholder="Enter your last Name"
            leftElement={<img src={lastNameIcon} alt="Last Name" />}
          />
          <MotusiInput
            type="phone"
            name="phoneNumber"
            controlStyle={{ marginTop: '20px', width: '100%' }}
            placeholder="Enter your phone number"
            onChange={handleChange}
            leftElement={<PhoneIcon />}
          />
        </>
      )}
      ActionComponent={({ handleSubmit }) => (
        <Button
          type="submit"
          color="primary"
          style={{ marginTop: 40, width: '100%' }}
          onClick={handleSubmit}
        >
          Create Account
        </Button>
      )}
      onSubmit={onSubmit}
    />
  );
};

ConfirmRegistrationDialog.defaultProps = {
  onClose: () => null,
  authData: {},
};

ConfirmRegistrationDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  authData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }),
};

export default ConfirmRegistrationDialog;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Box, Button, Link } from '@material-ui/core';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EmailIcon from '@material-ui/icons/Email';

import { useAuth } from '@motusi/app/modules/core/contexts/Auth';
import MotusiTitle from '@motusi/app/modules/common-ui/components/MotusiTitle';
import MotusiInput from '@motusi/app/modules/common-ui/components/MotusiInput';
import {
  FORGOT_PASSWORD_PATH,
  SIGN_UP_PATH,
} from '@motusi/app/modules/core/routing/routes/auth.routes';
import { DASHBOARD_PATH } from '@motusi/app/modules/core/routing/routes/root.routes';
import { useAuthStyles } from '@motusi/app/modules/pages/Auth/style';
import { showUAnimation } from '@motusi/styles/animations';

// Validation Setting
const validationSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

// Initial Values
const INITIAL_VALUES = {
  email: '',
  password: '',
};

// Component
const SignIn = () => {
  const classes = useAuthStyles();
  const history = useHistory();
  const { signIn, saveProfile } = useAuth();
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  /**
   * Sign In
   * @param {string} email
   * @param {string} password
   * @param setErrors
   */
  async function onSignInClick({ email, password }, { setErrors }) {
    try {
      await signIn(email, password);
      await saveProfile();
      history.push(DASHBOARD_PATH);
    } catch (error) {
      setErrors({ email: error.message });
    }
  }

  function changeTypeOfPasswordField() {
    setPasswordFieldType(passwordFieldType === 'text' ? 'password' : 'text');
  }

  return (
    <Box>
      <MotusiTitle style={{ ...showUAnimation(0.5), marginBottom: 40 }}>Sign In</MotusiTitle>

      <Formik
        validationSchema={validationSchema}
        initialValues={INITIAL_VALUES}
        onSubmit={onSignInClick}
      >
        {({ handleChange, handleSubmit }) => (
          <Form className={classes.authForm}>
            <MotusiInput
              controlStyle={showUAnimation(0.6)}
              onChange={handleChange}
              name="email"
              placeholder="Email Address"
              leftElement={<EmailIcon />}
            />
            <br />
            <br />
            <MotusiInput
              controlStyle={showUAnimation(0.7)}
              onChange={handleChange}
              type={passwordFieldType}
              name="password"
              placeholder="Password"
              leftElement={<EnhancedEncryptionIcon />}
              rightElement={
                <Box style={{ cursor: 'pointer' }} onClick={changeTypeOfPasswordField}>
                  {passwordFieldType === 'text' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Box>
              }
            />

            <Button
              type="submit"
              color="default"
              style={{ ...showUAnimation(0.8), marginTop: 30 }}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Link
              style={{ ...showUAnimation(0.9), marginTop: 30 }}
              href="/"
              onClick={(event) => {
                event.preventDefault();
                history.push(FORGOT_PASSWORD_PATH);
              }}
            >
              Forgot Password
            </Link>
            <Button
              color="primary"
              variant="contained"
              style={{ ...showUAnimation(0.9), marginTop: 30 }}
              onClick={() => {
                history.push(SIGN_UP_PATH);
              }}
            >
              Create an Account
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SignIn;

import { makeStyles } from '@material-ui/core';

import bg from '@motusi/assets/img/bg.png';

export const useAuthStyles = makeStyles((theme) => ({
  container: {
    padding: '51px 0 10px 24px',
    minHeight: 'calc(79vh - 83px)',
    boxSizing: 'border-box',
    background: `transparent url(${bg}) no-repeat center center`,
    backgroundSize: 'cover',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  text: {
    color: theme.palette.secondary['50'],
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '18px',
    marginTop: '33px',
    width: '95%',
  },
  phone: {
    maxWidth: '100%',
    height: '65vh',
    marginTop: '-7%',
  },
  authForm: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '361px',
  },
}));

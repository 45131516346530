import React, { createContext, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';

// Context
const PreloaderContext = createContext();

// Provider
export function PreloaderProvider({ children }) {
  const [preloaderStack, setPreloaderStack] = useState([]);
  const [isOnPreloader, setIsOnPreloader] = useState(false);

  /**
   * Start Preloader Method
   */
  function onPreloader() {
    setPreloaderStack([...preloaderStack, '']);
  }

  /**
   * Stop Preloader Method
   */
  function offPreloader() {
    setPreloaderStack([...preloaderStack.slice(-1)]);
  }

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        offPreloader();
        return response;
      },
      (error) => {
        offPreloader();
        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use(
      async (request) => {
        onPreloader();
        return request;
      },
      (error) => {
        offPreloader();
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    setIsOnPreloader(Boolean(preloaderStack.length));
  }, [preloaderStack]);

  return (
    <PreloaderContext.Provider value={{ isOnPreloader, onPreloader, offPreloader }}>
      {children}
    </PreloaderContext.Provider>
  );
}

PreloaderProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const usePreloader = () => useContext(PreloaderContext);

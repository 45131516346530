import { common, cyan } from '@material-ui/core/colors';

export default {
  root: {
    color: common.white,
    fontFamily: 'Oswald',
    backgroundColor: cyan['500'],
    textTransform: 'uppercase',
  },
};

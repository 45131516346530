import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EmailIcon from '@material-ui/icons/Email';

import { useAuth } from '@motusi/app/modules/core/contexts/Auth';
import MotusiTitle from '@motusi/app/modules/common-ui/components/MotusiTitle';
import MotusiInput from '@motusi/app/modules/common-ui/components/MotusiInput';
import { SIGN_IN_PATH } from '@motusi/app/modules/core/routing/routes/auth.routes';
import { useAuthStyles } from '@motusi/app/modules/pages/Auth/style';
import ConfirmRegistrationDialog from '@motusi/app/modules/pages/Auth/components/SignUp/dialogs/ConfirmRegistrationDialog';
import {
  DEFAULT_MAX_INPUT_LENGTH,
  MAX_EMAIL_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_REGEX,
} from '@motusi/app/modules/core/auth/validation/constants';
import { showUAnimation } from '@motusi/styles/animations';

// Validation Setting
const validationSchema = yup.object({
  email: yup
    .string()
    .trim(`The field can't include leading and trailing spaces`)
    .strict()
    .required('Email is required')
    .email('Please enter a valid email')
    .max(MAX_EMAIL_LENGTH, `You exceeded max email length (${MAX_EMAIL_LENGTH} characters)`),
  password: yup
    .string()
    .trim(`The field can't include leading and trailing spaces`)
    .strict()
    .required('Password is required')
    .matches(PASSWORD_REGEX, `Password doesn't follow the requirements`)
    .min(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters`)
    .max(
      DEFAULT_MAX_INPUT_LENGTH,
      `You exceeded max password length (${DEFAULT_MAX_INPUT_LENGTH} characters)`
    ),
});

// Initial Values
const INITIAL_VALUES = {
  email: '',
  password: '',
};

// Component
const SignUp = () => {
  const classes = useAuthStyles();
  const history = useHistory();
  const { signUp, resendConfirmationCode } = useAuth();
  const [authData, setAuthData] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  /**
   * Sign Up
   * @param {string} email
   * @param {string} password
   * @param setErrors
   */
  async function onSignUpClick({ email, password }, { setErrors }) {
    try {
      await signUp(email, password);
      setIsOpenDialog(true);
      setAuthData({ email, password });
    } catch ({ code, message }) {
      setErrors({ email: message });

      if (code === 'UsernameExistsException') {
        try {
          await resendConfirmationCode(email);
          setIsOpenDialog(true);
          setAuthData({ email, password });
        } catch (error) {
          setErrors({ password: error.message, email: message });
          setIsOpenDialog(false);
        }
      }
    }
  }

  function changeTypeOfPasswordField() {
    setPasswordFieldType(passwordFieldType === 'text' ? 'password' : 'text');
  }

  return (
    <Box>
      <MotusiTitle style={{ ...showUAnimation(0.5), marginBottom: 40 }}>Sign Up</MotusiTitle>

      <Formik
        validationSchema={validationSchema}
        initialValues={INITIAL_VALUES}
        onSubmit={onSignUpClick}
      >
        {({ handleChange, handleSubmit }) => (
          <Form className={classes.authForm}>
            <MotusiInput
              controlStyle={showUAnimation(0.6)}
              onChange={handleChange}
              name="email"
              placeholder="Email Address"
              leftElement={<EmailIcon />}
            />
            <br />
            <br />
            <MotusiInput
              controlStyle={showUAnimation(0.7)}
              onChange={handleChange}
              type={passwordFieldType}
              name="password"
              placeholder="Password"
              leftElement={<EnhancedEncryptionIcon />}
              rightElement={
                <Box style={{ cursor: 'pointer' }} onClick={changeTypeOfPasswordField}>
                  {passwordFieldType === 'text' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Box>
              }
            />
            <Button
              style={{ ...showUAnimation(0.8), marginTop: 30 }}
              type="submit"
              color="default"
              onClick={handleSubmit}
            >
              Sign Up
            </Button>

            <Button
              style={{ ...showUAnimation(0.9), marginTop: 30 }}
              color="primary"
              variant="contained"
              onClick={() => {
                history.push(SIGN_IN_PATH);
              }}
            >
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
      <ConfirmRegistrationDialog
        onClose={() => setIsOpenDialog(false)}
        authData={authData}
        open={isOpenDialog}
      />
    </Box>
  );
};

export default SignUp;

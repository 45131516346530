export const preloaderContainerStyles = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
  bgcolor: 'rgba(0, 0, 0, 0.4)',
};

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';

import App from '@motusi/app/App';
import customTheme from '@motusi/styles/theme/theme';
import ErrorBoundary from '@motusi/app/modules/core/ErrorBoundary';
import { AuthProvider } from '@motusi/app/modules/core/contexts/Auth';
import { SpacesProvider } from '@motusi/app/modules/core/contexts/Spaces';
import MotusiPreloader from '@motusi/app/modules/common-ui/components/MotusiPreloader';
import { PreloaderProvider } from '@motusi/app/modules/core/contexts/Preloader';

import './index.scss';

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <PreloaderProvider>
        <AuthProvider>
          <SpacesProvider>
            <ThemeProvider theme={customTheme}>
              <App />
              <MotusiPreloader />
            </ThemeProvider>
          </SpacesProvider>
        </AuthProvider>
      </PreloaderProvider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById('root')
);

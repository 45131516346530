import { makeStyles } from '@material-ui/core';

export const useSpaceSettingsDialogStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '45vw',
    maxWidth: '550px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  deleteButton: {
    marginTop: '20px',
    width: '100%',
    backgroundColor: theme.palette.error.main,

    '&:hover': {
      backgroundColor: theme.palette.error.main,
      opacity: 0.7,
    },
  },
  actionContainer: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
  },
}));

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    const { error, hasError, errorInfo } = this.state;
    const { children } = this.props;

    return hasError ? (
      <>
        <span>Something went wrong</span>
        <div>{error}</div>
        <div>{errorInfo}</div>
      </>
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ErrorBoundary;

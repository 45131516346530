import React from 'react';
import InputMask from 'react-input-mask';

const formatChars = {
  9: '[0-9]',
  a: '[A-Za-z]',
  '*': '[A-Za-z0-9]',
};

function PhoneMask(props) {
  return <InputMask mask="(999) 999-9999" maskChar="" formatChars={formatChars} {...props} />;
}

export { PhoneMask };

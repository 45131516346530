import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';

import MotusiTitle from '@motusi/app/modules/common-ui/components/MotusiTitle';
import MotusiInput from '@motusi/app/modules/common-ui/components/MotusiInput';
import { useAuthStyles } from '@motusi/app/modules/pages/Auth/style';
import { SIGN_IN_PATH } from '@motusi/app/modules/core/routing/routes/auth.routes';
import { useAuth } from '@motusi/app/modules/core/contexts/Auth';
import ConfirmPasswordDialog from '@motusi/app/modules/pages/Auth/components/ForgotPassword/dialogs/ConfirmPasswordDialog';
import { showUAnimation } from '@motusi/styles/animations';

// Validation Setting
const validationSchema = yup.object({
  email: yup.string().required('Email is required'),
});

// Initial Values
const INITIAL_VALUES = {
  email: '',
};

// Component
const ForgotPassword = () => {
  const classes = useAuthStyles();
  const history = useHistory();
  const { forgotPassword } = useAuth();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');

  /**
   * Forgot Password Method
   * @param {string} email
   * @param setErrors
   */
  async function onForgotPasswordClick({ email }, { setErrors }) {
    try {
      await forgotPassword(email);
      setCurrentEmail(email);
      setIsOpenDialog(true);
    } catch (error) {
      setErrors({ email: error.message });
    }
  }

  return (
    <Box>
      <MotusiTitle style={{ ...showUAnimation(0.5), marginBottom: 40 }}>
        Forgot Password
      </MotusiTitle>

      <Formik
        validationSchema={validationSchema}
        initialValues={INITIAL_VALUES}
        onSubmit={onForgotPasswordClick}
      >
        {({ handleChange, handleSubmit }) => (
          <Form className={classes.authForm}>
            <MotusiInput
              controlStyle={showUAnimation(0.6)}
              onChange={handleChange}
              name="email"
              placeholder="Enter your email"
              leftElement={<EmailIcon />}
            />

            <Button
              style={{ ...showUAnimation(0.7), marginTop: 30 }}
              type="submit"
              color="default"
              onClick={handleSubmit}
            >
              Forgot Password
            </Button>

            <Button
              color="primary"
              variant="contained"
              style={{ ...showUAnimation(0.8), marginTop: 30 }}
              onClick={() => {
                history.push(SIGN_IN_PATH);
              }}
            >
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
      <ConfirmPasswordDialog
        onClose={() => setIsOpenDialog(false)}
        open={isOpenDialog}
        email={currentEmail}
      />
    </Box>
  );
};

export default ForgotPassword;

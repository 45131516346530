import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { v4 } from 'uuid';
import PropTypes from 'prop-types';

import { isPublicRoute } from '@motusi/app/modules/core/routing/routing.utils';
import { SIGN_IN_PATH } from '@motusi/app/modules/core/routing/routes/auth.routes';
import { tokenStorage } from '@motusi/app/modules/core/services';

const Router = ({ routes }) => {
  return (
    <Switch>
      {routes.map((route) => {
        const { path, component: Component, exact, redirect, notReloadableKey } = route;
        return (
          <Route
            key={notReloadableKey || v4()}
            path={path}
            exact={exact}
            render={(props) => {
              if (!tokenStorage.read()) {
                return isPublicRoute(path) ? (
                  <Component {...props} />
                ) : (
                  <Redirect to={SIGN_IN_PATH} />
                );
              }

              return redirect ? <Redirect to={redirect} /> : <Component {...props} />;
            }}
          />
        );
      })}
    </Switch>
  );
};

Router.defaultProps = {
  routes: [],
};

Router.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      exact: PropTypes.bool,
      component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      redirect: PropTypes.string,
    })
  ),
};

export default Router;

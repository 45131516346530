import { AUTH_PATH } from '@motusi/app/modules/core/routing/routes/root.routes';
import SignIn from '@motusi/app/modules/pages/Auth/components/SignIn';
import SignUp from '@motusi/app/modules/pages/Auth/components/SignUp';
import ForgotPassword from '@motusi/app/modules/pages/Auth/components/ForgotPassword';

// Routes' paths constants
export const SIGN_IN_PATH = `${AUTH_PATH}/sign-in`;
export const SIGN_UP_PATH = `${AUTH_PATH}/sign-up`;
export const FORGOT_PASSWORD_PATH = `${AUTH_PATH}/forgot-password`;

// Routes config
export const AUTH_ROUTES = [
  {
    path: `${AUTH_PATH}/`,
    exact: true,
    redirect: SIGN_IN_PATH,
  },
  {
    path: SIGN_IN_PATH,
    component: SignIn,
    exact: true,
  },
  {
    path: SIGN_UP_PATH,
    component: SignUp,
    exact: true,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    component: ForgotPassword,
    exact: true,
  },
  {
    path: `${AUTH_PATH}/*`,
    redirect: SIGN_IN_PATH,
  },
];

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { v4 } from 'uuid';
import MoreVert from '@material-ui/icons/MoreVert';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SettingsIcon from '@material-ui/icons/Settings';

import { useAuth } from '@motusi/app/modules/core/contexts/Auth';
import { useSpaces } from '@motusi/app/modules/core/contexts/Spaces';
import { useHeaderStyles } from '@motusi/app/modules/common-ui/components/Header/style';
import UserProfileDialog from '@motusi/app/modules/common-ui/dialogs/UserProfileDialog';
import SpaceSettingsDialog from '@motusi/app/modules/common-ui/dialogs/SpaceSettingsDialog';
import { ROLE_NAMES } from '@motusi/app/modules/core/rest/rest.utils';
import {
  DASHBOARD_PATH,
  NEW_SPACE_PATH,
  SPACE_PATH,
} from '@motusi/app/modules/core/routing/routes/root.routes';
import logo from '@motusi/assets/img/logo.png';
import avatar from '@motusi/assets/img/avatar.png';

const Header = () => {
  const classes = useHeaderStyles();
  const history = useHistory();
  const { profile } = useAuth();
  const [buttonElement, setButtonElement] = useState(null);
  const { spaces, selectedSpaceId, setSelectedSpaceId } = useSpaces();
  const [openUserProfileDialog, setOpenUserProfileDialog] = useState(false);
  const [spaceSettingsDialogId, setSpaceSettingsDialogId] = useState(null);
  /**
   * Close Space Menu
   */
  function onCloseMenu() {
    setButtonElement(null);
  }

  /**
   * Open Space Menu
   * @param event
   */
  function onOpenMenu(event) {
    setButtonElement(event.currentTarget);
  }

  return (
    <AppBar position="static">
      <Toolbar style={{ paddingLeft: '10px' }}>
        <Box className={classes.logoContainer}>
          <Box className={classes.logoAnimation} onClick={() => history.push(DASHBOARD_PATH)}>
            <Avatar alt="Logo" src={logo} className={classes.logo} />
          </Box>
        </Box>
        {profile && (
          <Box display="flex" alignItems="center">
            {selectedSpaceId ? (
              <Box style={{ marginRight: '25px' }}>
                <Button
                  color="inherit"
                  aria-haspopup="true"
                  aria-controls="customized-menu"
                  onClick={onOpenMenu}
                  endIcon={<MoreVert />}
                  className={classes.menuButton}
                >
                  {spaces.find(({ space }) => +space.id === +selectedSpaceId)?.space.name}
                </Button>

                <Menu
                  keepMounted
                  id="customized-menu"
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: -5, horizontal: 'left' }}
                  anchorEl={buttonElement}
                  open={Boolean(buttonElement)}
                  PaperProps={{ className: classes.menuPaper }}
                  onClose={onCloseMenu}
                >
                  {spaces.map(({ space: { id, name, logo: spaceLogo }, user: { role } }) => (
                    <MenuItem
                      key={id}
                      selected={+selectedSpaceId === +id}
                      onClick={() => {
                        onCloseMenu();
                        if (+selectedSpaceId !== +id) {
                          setSelectedSpaceId(id);
                          history.push(`${SPACE_PATH}/${id}`);
                        }
                      }}
                    >
                      <ListItemIcon style={{ minWidth: '42px' }}>
                        <img className={classes.avatar} src={spaceLogo || avatar} alt="Logo" />
                      </ListItemIcon>
                      <Box className={classes.menuItemContainer}>
                        <Box>{name}</Box>
                        {role === ROLE_NAMES.admin ? (
                          <ListItemIcon
                            onClick={() => setSpaceSettingsDialogId(id)}
                            className={classes.menuItemIcon}
                          >
                            <SettingsIcon className={classes.settingsIcon} />
                          </ListItemIcon>
                        ) : null}
                      </Box>
                    </MenuItem>
                  ))}

                  <MenuItem
                    key={v4()}
                    onClick={() => {
                      onCloseMenu();
                      setSelectedSpaceId(null);
                      history.push(NEW_SPACE_PATH);
                    }}
                  >
                    <ListItemIcon style={{ minWidth: '42px' }}>
                      <AddBoxIcon className={classes.menuAddIcon} />
                    </ListItemIcon>
                    Create A New Space
                  </MenuItem>
                </Menu>
              </Box>
            ) : null}

            <Box
              className={classes.profileContainer}
              onClick={() => setOpenUserProfileDialog(true)}
            >
              <Avatar
                className={classes.profileAvatar}
                alt="Avatar"
                src={(profile && profile.photo) || avatar}
              />

              <Typography variant="h6" className={classes.profileName}>
                {profile.firstName ? `${profile.firstName} ${profile.lastName}` : 'Your Name'}
              </Typography>
            </Box>
          </Box>
        )}
      </Toolbar>

      <UserProfileDialog
        open={openUserProfileDialog}
        onClose={() => setOpenUserProfileDialog(false)}
      />

      <SpaceSettingsDialog
        id={spaceSettingsDialogId}
        onClose={() => setSpaceSettingsDialogId(null)}
      />
    </AppBar>
  );
};

export default Header;

import { common } from '@material-ui/core/colors';
import colors from '@motusi/styles/theme/colors';

export default {
  root: {
    color: colors.secondary['100'],
    fontFamily: 'Open Sans',
    backgroundColor: common.white,
    fontSize: '15px',
    lineHeight: '15px',
    fontWeight: 400,
    borderRadius: '22px',
  },

  input: {
    padding: '12px 21px',
  },
};

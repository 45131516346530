import { common } from '@material-ui/core/colors';
import colors from '@motusi/styles/theme/colors';

export default {
  root: {
    fontSize: '17px',
    color: colors.primary['300'],
    fontWeight: 700,
    fontFamily: 'Oswald',

    '&$selected': {
      color: common.white,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: colors.secondary['500'],
    },
  },
};
